
/*****************************************************************************/
/*  THEMES                                                                   */
/*****************************************************************************/

//  Light (default) theme
body, body[data-theme="light"] {
  --text-primary: #09090B;
  --text-secondary: #373743;
  --text-tertiary: #65657B;
  --text-quaternary: #9A9AAC;
  --text-pink: #D61F7B;
  --text-white: #FFFFFF;
}

//  Dark theme
body[data-theme="dark"] {
  --text-primary: #E9E9ED;
  --text-secondary: #B0B0BF;
  --text-tertiary: #787890;
  --text-quaternary: #49495A;
  --text-pink: #D61F7B;
  --text-white: #FFFFFF;
}
